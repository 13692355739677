.logoContainer {
    background-color: #bedde6;
    padding-block: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 15%; */
}

.profileImg {
    height: 40px;
    width: 40px;
    background-color: #c5cee0;
    border: 1px solid #566588;
    border-radius: 50%;
}

.userDetailsContainer {
    background-color: #247d98;
    color: #fff;
    display: flex;
    padding-block: 1.5em;
    padding-left: 1em;
    align-items: center;
    /* height: 10%; */
}

.userContainer {
    margin-left: 10px;
}

.userName {
    font-weight: bold;
    margin: 0px;
}

.userRole {
    color: #fff;
    margin: 0px;
}

.userRoleDropdown {
    color: rgb(151, 149, 149);
    margin: 0px;
}

.navItem a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 0.5rem 1rem;
}

.navItem a:hover {
    color: #c5a47e;
    background: #f9fbfd;
}

.focused {
    color: #c5a47e !important;
    background: #f9fbfd;
    border-right: 0.5em solid #c5a47e;
    width: 100%;
}


/* .navItem a {
    border-right: 0.6em solid #54c0ce;
    background-color: red;
    color: #566588;
} */