.mainContainer {
    overflow: auto;
    height: calc(100% - 70px);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.activityDetailsTitle {
    background-color: #e5f6f8;
    border-radius: 10px;
    padding-block: 1.5em;
    padding-inline: 1em;
}

.activityDetails {
    margin-top: 2rem;
    width: 95%;
}

.detailsTitle {
    font-size: 0.9em;
    font-weight: 400;
    color: #8f9bb3;
}

.detailsContent {
    font-size: 1.1em;
    font-weight: 500;
    color: black;
}

.addPollBtn {
    border: none;
    background-color: #2096bb;
    color: #fff;
    /* width: 40px; */
    height: 40px;
    border-radius: 10px;
    margin-inline: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 0.5em;
    font-weight: 500;
}

.rowContainer {
    margin-top: 20px;
}

.searchInput {
    width: 100%;
    padding-block: 0.3rem;
    padding-left: 1rem;
    padding-right: 2.5rem;
    border: solid 2px rgba(197, 206, 224, 0.5);
    border-radius: 10px;
    outline: none;
}

.searchInput::placeholder {
    color: #c5cee0;
    opacity: 1;
}

.pagintionNextPrevButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: #c5cee0;
}

.pagintionButton {
    border: none;
    background-color: transparent;
    border-bottom: solid 3px #0095ff;
    padding-block: 1em;
    font-weight: bold;
}

.pagintionButtonNxt {
    border: none;
    background-color: transparent;
    color: #c5cee0;
    padding-block: 1em;
}

.tableContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.customFileUpload {
    background-color: #e5f6f8;
    color: #54c0ce;
    font-weight: 500;
    border-radius: 10px;
    /* display: inline-block; */
    /* padding: 0.5rem 1rem; */
    width: 250px;
    height: 50px;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sectionContainerTitle {
    border-bottom: solid 2px #eff4fa;
    margin-bottom: 1em;
    padding-block: 1em;
    display: flex;
}

.docContainer {
    max-height: 250px;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.fileContainer {
    margin-bottom: 1.5em;
}

.docButtons {
    border: none;
    background-color: #e5f6f8;
    color: #54c0ce;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-inline: 0.5em;
}

.rowContainerDoc {
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 10px;
    padding-inline: 1em;
}

.addButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.nameFile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30%;
}