.adminRole {
    background-color: #c5a47e;
}

.therapeuteRole {
    background-color: #2096bb;
}

.educateurRole {
    background-color: #bedde6;
}