.App {
    display: flex;
    width: 100%;
    align-items: stretch;
    font-family: 'Open Sans', sans-serif;
    background: #f6f9fc;
}

body {
    background: #f6f9fc;
    margin: 0;
    padding: 0;
}

.colap {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


/* a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
} */


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #0f5f78;
    /* color: #566588; */
    height: 100vh;
    margin-left: -250px;
    transition: all 0.5s;
    overflow-y: auto;
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}


/* .sidebar-header {
    background: #a4dee6;
} */


/* .sidebar-header h3 {
    color: #fff;
    padding: 1em;
} */


/* .sidebar ul p {
    color: #fff;
    padding: 10px;
} */


/* .menu-open {
    background: #6d7fcc;
} */


/* .nav-item a:hover {
    color: #54c0ce;
    background: #f9fbfd;
} */


/* .nav-item a {
    color: #566588;
} */

.items-menu {
    color: #fff;
    background: #6d7fcc;
}


/* li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
} */


/* .sidebar-header>span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
} */


/* .side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
} */


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.MuiButtonBase-root {
    padding: 0px !important;
}

.Kalend__Calendar__root {
    width: 100% !important;
}

.content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    body {
        overflow: hidden;
    }
    /* .content.is-open {
        margin-left: 100%;
    } */
    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }
    .sidebar.is-open>.sidebar-header span {
        display: unset;
    }
    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #c5cee0;
    border-radius: 15px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}