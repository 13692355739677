.mainContainer {
    overflow: auto;
    height: calc(100% - 70px);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.optionMenu {
    display: flex;
    justify-content: flex-end;
    padding-right: 2.5%;
}

.saveButton {
    padding-block: 0.8em;
    padding-inline: 2em;
    color: white;
    background-color: #54c0ce;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
}

.addButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.activityDetailsTitle {
    background-color: #e5f6f8;
    border-radius: 10px;
    padding-block: 1.5em;
    padding-inline: 1em;
}

.detailsTitle {
    font-size: 0.9em;
    font-weight: 400;
    color: #8f9bb3;
}

.detailsContent {
    font-size: 1.1em;
    font-weight: 500;
    color: black;
}

.activityDetails {
    margin-top: 2rem;
    width: 95%;
}

.rowContainer {
    margin-top: 2rem;
    background-color: #fff;
    border-radius: 10px;
    padding-inline: 1rem;
}

.groupRowContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.groupColContainer {
    background-color: #fff;
    border-radius: 10px;
    padding-inline: 1rem;
}

.sectionContainerTitle {
    border-bottom: solid 2px #eff4fa;
    margin-bottom: 1em;
    padding-block: 1em;
    display: flex;
}

.docButtons {
    border: none;
    background-color: #e5f6f8;
    color: #54c0ce;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-inline: 0.5em;
}

.fileContainer {
    margin-bottom: 1.5em;
}

.docContainer {
    max-height: 250px;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.historiqueContainer {
    max-height: 600px;
    min-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.customFileUpload {
    background-color: #e5f6f8;
    color: #54c0ce;
    font-weight: 500;
    border-radius: 10px;
    width: 250px;
    height: 50px;
    cursor: pointer;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.colHistory {
    background-color: #fff;
    border-radius: 10px;
    width: 47%;
}

.rowHistoryContainer {
    margin-top: 2rem;
    padding-inline: 1em;
    display: flex;
    justify-content: space-around;
}

.kidItem {
    width: 95%;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #e5f6f8;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-weight: 500;
}

.participantKids {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
}