.mainContainer {
    overflow: auto;
    height: calc(100% - 70px);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.formRow {
    padding-left: 1rem;
}

.saveBtnRow {
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.saveBtn {
    padding-block: 1em;
    padding-inline: 2em;
    color: white;
    background-color: #c5a47e;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
    margin-left: 2em;
}

.previousInterventions {
    padding-left: 1rem;
    border-left: 1px solid #c1c4c8;
}

.tablePreviousInterventions {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.tablePreviousInterventions thead,
.tablePreviousInterventions tbody {
    display: inline-block !important;
}

.tablePreviousInterventions td {
    border: none;
    padding-inline: 1rem;
    /* width: 2rem; */
}

.tablePreviousInterventions {
    border-radius: 0px !important;
}

.tablePreviousInterventions thead {
    background-color: white;
    color: black;
}

.tableRows {
    padding-block: 1.6rem !important;
}

.tableRowsKeys {
    padding-block: 1.6rem !important;
}

.interventionGeneralInfo {
    font-weight: bold;
    font-size: 1.1rem;
    color: #8f9bb3;
}