.mainContainer {
    overflow: auto;
    height: calc(100% - 70px);
    padding-top: 2rem;
}

.optionMenu {
    display: flex;
    justify-content: flex-end;
    padding-right: 3%;
}

.paginationInput {
    margin-left: 1em;
    width: 80px;
    padding-block: 0.3rem;
    padding-left: 1rem;
    /* padding-right: 2.5rem; */
    border: solid 2px rgba(197, 206, 224, 0.5);
    border-radius: 10px;
    outline: none;
}

.addButton {
    padding-block: 1em;
    padding-inline: 2em;
    color: white;
    background-color: #2096bb;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
    margin-left: 2em;
}

.modeItems {
    border: solid 2px #eff4fa;
    background-color: #c5a47e;
    height: 50px;
    width: 60px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.modeList {
    border: solid 2px #eff4fa;
    background-color: transparent;
    height: 50px;
    width: 60px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tableContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

table {
    width: 95%;
    margin-top: 2em;
    border-collapse: collapse;
    border-radius: 1em;
    overflow: hidden;
}

td {
    background-color: #ffffff;
    color: black;
    padding-block: 1em;
    padding-left: 2em;
    border-bottom: 1px solid #ccc;
}

th {
    padding-left: 2em;
    padding-block: 1em;
}

thead,
tfoot {
    background-color: #eff4fa;
    color: #8f9bb3;
}

p {
    margin: 0px;
}

.rowContainer {
    margin-top: 20px;
}

.pagintionNextPrevButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    color: #c5cee0;
}

.pagintionButton {
    border: none;
    background-color: transparent;
    border-bottom: solid 3px #0095ff;
    padding-block: 1em;
    font-weight: bold;
}

.pagintionButtonNxt {
    border: none;
    background-color: transparent;
    color: #c5cee0;
    padding-block: 1em;
}

.searchInput {
    width: 100%;
    padding-block: 0.3rem;
    padding-left: 1rem;
    padding-right: 2.5rem;
    border: solid 2px rgba(197, 206, 224, 0.5);
    border-radius: 10px;
    outline: none;
}

.searchInput::placeholder {
    color: #c5cee0;
    opacity: 1;
}