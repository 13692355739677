.mainContainer {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 70px);
    padding-top: 1rem;
    position: relative;
}

.calendarContainer {
    height: 100%;
    background-color: white;
    border-radius: 20px;
    border: solid 2px #eff4fa;
}

.ActivityList {
    background-color: white;
    border-radius: 20px;
    border: solid 2px #eff4fa;
    height: 100%;
}

.sectionTitle {
    font-weight: 500;
    height: 8%;
    align-items: center;
    border-bottom: solid 2px #eff4fa;
    display: flex;
    justify-content: space-between;
}

.addButton {
    width: 30px;
    height: 30px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.todaysActScrollable {
    height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
}

.teamCard {
    padding-block: 1.3rem;
    padding-left: 1rem;
    background-color: #f0e7de;
    border-radius: 10px;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.popuptitles {
    color: #a6afc2;
}

.rowContainer {
    margin-top: 0.5em;
}

.popupInfo {
    font-weight: 400;
}