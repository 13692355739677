.chartContainer {
    background-color: #fff;
    /* width: 48%; */
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 8px 0 rgba(0, 0, 0, 0.1);
    /* min-height: 200px; */
    padding-bottom: 1rem;
    padding-inline: 1rem;
    display: flex;
    flex-direction: column;
}

.chartTitleContainer {
    margin-bottom: 1rem;
    padding-block: 1rem;
    border-bottom: 1px solid #e5f6f8;
}