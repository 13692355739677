p {
  margin: auto;
}

.mainContainer {
  background-image: url("../../assets/images/pnh_team.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100vh !important;
  overflow-x: hidden;
}

.loginContainer {
  background-color: white;
  margin-top: 2em;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.loginForm {
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginFormInput {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inputField {
  width: 90% !important;
  /* background-color: #f2f3ff; */
}

.loginFormTitle {
  color: #566588;
  margin-bottom: 1em;
}

.loginFormButton {
  border: none;
  background-color: #c5a47e;
  color: white;
  font-weight: 500;
  padding-block: 0.5rem;
  padding-inline: 5rem;
  border-radius: 10px;
}
